<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div  class="rn-column-area rn-section-gap mt--150">
      <v-container>
            <h3 class="mb--100">Πολιτική Απορρήτου</h3>
            
            <p>Η προστασία του απορρήτου σας είναι σημαντική για την Roadcube. Γι’ αυτό και έχουμε αναπτύξει μια Δήλωση Απορρήτου η οποία καλύπτει 
              το πώς επεξεργαζόμαστε τις πληροφορίες σας. </p><p>Αφιερώστε λίγο χρόνο για να εξοικειωθείτε με τις πρακτικές απορρήτου μας και ενημερώστε μας
               αν έχετε οποιεσδήποτε απορίες. </p><p><b>Ποιος είναι ο Υπεύθυνος Επεξεργασίας;</b></p><p>Υπεύθυνος επεξεργασίας των προσωπικών σας δεδομένων 
               είναι η εταιρεία με την επωνυμία Roadcube Inc Υποκατάστημα Αλλοδαπής (εφεξής η «Εταιρεία»), με έδρα την Καλλιθέα (Λεωφόρος Ποσειδώνος 18),
                τηλέφωνο επικοινωνίας +30 210 300 66 33 (Δευτέρα έως Παρασκευή 10:00-18:00).</p><p>Θα θέλαμε να σας γνωστοποιήσουμε ότι για ορισμένες 
                πράξεις επεξεργασίας η Εταιρεία μας μπορεί να λειτουργεί ως εκτελών την επεξεργασία ή ως από κοινού υπεύθυνος επεξεργασίας. Ανεξάρτητα 
                από τον ρόλο της και στις δύο περιπτώσεις τηρούνται όλες οι σχετικές συμφωνίες που προβλέπει ο ΓΚΠΔ οι οποίες ορίζουν τους ρόλους και 
                τις υποχρεώσεις των εκάστοτε μερών.</p><p><b>Πως μπορώ να επικοινωνήσω για ερώτημα που αφορά τα προσωπικά μου δεδομένα? </b></p><p>Για ερωτήσεις/
                διευκρινίσεις σχετικά με την επεξεργασία των προσωπικών σας δεδομένων μπορείτε να επικοινωνήσετε τηλεφωνικά στο +30 210 300 66 33 
                (Δευτέρα έως Παρασκευή 10:00-18:00), ή να μας αποστείλετε μείλ στην ηλεκτρονική διεύθυνση: info@roadcube.com. </p><p>Για να ασκήσετε 
                τα δικαιώματά σας, προτείνουμε για την ασφαλέστερη και πλήρη περιγραφή του σχετικού αιτήματός σας, να μας αποστείλετε μήνυμα στην 
                ηλεκτρονική διεύθυνση: info@roadcube.com. </p><p><b>Ποια προσωπικά σας δεδομένα, για ποιον σκοπό και με ποια νομική βάση τα επεξεργαζόμαστε?
                </b></p><p>Δεδομένα που είναι απαραίτητα για την εγγραφή του χρήστη στην εφαρμογή και την συμμετοχή στα προγράμματα πιστότητας. πχ: όνομα, 
                επίθετο, αριθμός τηλεφώνου, διεύθυνση ηλεκτρονικού ταχυδρομείου, κωδικός πρόσβασης. Βάση επεξεργασίας είναι η εκτέλεση της συμβατικής 
                σχέσης για την εγγραφή στα προγράμματα πιστότητας που σας προσφέρουμε. </p><p>Δεδομένα που είναι απαραίτητα για την εκτέλεση του προγράμματος
                 πιστότητας (όπως είναι η συλλογή και εξαργύρωση πόντων, έκδοση κουπονιών εξαργύρωσης, αποστολή δώρων, εκπτώσεις και προσφορές): 
                 επεξεργαζόμαστε τα στοιχεία εγγραφής σας, τα στοιχεία που μπορεί να μας παρέχετε προκειμένου να εκτελεστεί κάποιο πρόγραμμα πιστότητας 
                 (π.χ διεύθυνση αποστολής δώρου) και στοιχεία που προκύπτουν από την κάρτα που χρησιμοποιείτε σχετικά με τη συλλογή πόντων, τα κουπόνια 
                 εξαργύρωσης, τα δώρα κτλ. Βάση επεξεργασίας είναι η εκτέλεση της συμβατικής σχέσης.</p><p><b>Δεδομένα χρηστών που μας παρέχετε με τη θέλησή 
    σας:</b> </p><p><b>Δεδομένα τοποθεσίας</b> (μπορείτε να επιλέξετε οικειοθελώς την ενεργοποίηση της τοποθεσίας σας προκειμένου να είναι πιο εύκολη
    καιγρήγορη η εξυπηρέτησή σας) </p><p><b>Φωτογραφία:</b> οικειοθελώς στο προφίλ χρήστη στον λογαριασμό που δημιουργείτε μπορείτε να τοποθετήσετε 
    φωτογραφία προφίλ, η οποία αποθηκέυετε στη συσκευή σας και όχι στη βάση δεδομένων μας. </p><p><b>Εμπορική επικοινωνία:</b> Κατόπιν συγκατάθεσής
      σας γίνεται επεξεργασία των δεδομένων επικοινωνίας σας για τον σκοπό εμπορικής προώθησης (π.χ:πόντοι, εξαργύρωση, κουπόνια, δώρα).
      Επίσης υπάρχει και η δυνατότητα κατόπιν της συναίνεσής σας να σας αποσταλεί εξατομικευμένη εμπορική προώθηση λαμβάνοντας υπόψη 
      στοιχεία σας όπως περιοχή κατοικίας του χρήστη, αριθμός συναλλαγών, πρατήριο στο οποίο κάνει αγορές ο χρήστης. Αν επιθυμείτε να 
      λαμβάνετε διαφημιστικές ενημερώσεις για τις υπηρεσίες μας σε συγκεκριμένο μέσο επικοινωνίας (π.χ θέλετε να λαμβάνετε ενημερωτικό 
      υλικό μόνο μέσω μειλ) μπορείτε να επιλέξετε το μέσον πηγαίνοντας στο προφιλ σας που έχει δημιουργηθεί στην εφαρμογή. Εκεί έχετε την
        επιλογή ρύθμισης και επιλογής του μέσου εμπορικής επικοινωνίας που επιθυμείτε. Θα θέλαμε να σας καταστήσουμε σαφές ότι σε κάθε 
        περίπτωση όπου λαμβάνετε διαφημιστικό ενημερωτικό μήνυμα σε οποιοδήποτε μέσο εμπορικής προώθησης, έχετε κάθε στιγμή τη δυνατότητα
        να καταργήσετε την εγγραφή σας οπότε και θα σταματήσει η αποστολή μάρκετινγκ. Σχετικές οδηγίες για τον τρόπο ανάκλησης αυτής της 
        συγκατάθεσης σας παρέχονται στο τέλος κάθε μηνύματος εμπορικής προώθησης. Στην περίπτωση που λαμβάνετε μήνυμα εμπορικης προωθησης
          μεσω τηλεφωνου, αν θελησετε να ανακαλεσετε τη συγκαταθεση σας παρακαλούμε να μας αποστειλετε σχετικο μειλ στο info@roadcube.com 
          ή να καλεσετε στο τηλεφωνικό μας κέντρο. Βέβαια σε κάθε περίπτωση έχετε το δικαίωμα να απενεργοποιήσετε την επιλογή του μέσου 
          στο προφιλ σας οπότε και θα λαβουμε τη σχετικη ενημερωση και θα σταματησουμε να σας στελνουμε διαφημιστικό προιον στο τηλέφωνό σας.
          </p><p><b>Άλλα δεδομένα</b> που μας παρέχετε και τα επεξεργαζόμαστε προκειμένου να εκπληρώσουμε τις υποχρεώσεις μας απέναντί σας και να 
          ικανοποιήσουμε το έννομο συμφέρον μας: πληροφορίες που μας παρέχετε τηλεφωνικά συμπεριλαμβανομένων των καταγραφών των κλήσεών σας
            μέσω της υπηρεσίας εξυπηρέτησης πελατών μας στο τηλεφωνικό μας κέντρο ή σε αλληλογραφία μέσω ηλεκτρονικού ταχυδρομείου μαζί μας.
            Κατά την επικοινωνία σας μαζί μας επεξεργαζόμαστε τα απολύτως απαραίτητα για τον εκάστοτε σκοπό δεδομένα σας προκειμένου να 
            απαντήσουμε στο ερώτημά σας, να σας παρέχουμε τις απαιτούμενες πληροφορίες, να εκπληρώσουμε τις υποχρεώσεις μας απέναντί σας 
και να εξασφαλίσουμε την ασφάλεια των συναλλαγών σας με εμάς. </p><p>Στο πλαίσιο του μηχανήματος ποντοδοσίας έχετε τη δυνατότητα να εξαργυρώνετε 
μόνοι σας τους πόντους στα εκάστοτε καταστήματα – συνεργάτες μας. Στο πλαίσιο εκπλήρωσης αυτού του προγράμματος προκειμένου να εκπληρωθεί αυτή η
 παροχή προς εσάς δύναται να σας ζητηθεί κάποιο στοιχείο ταυτοποίησής σας (π.χ αριθμός τηλεφώνου) για να μπορέσετε να εισέλθετε στο σύστημα ποντοδοσίας.
  </p><p></p><p><b>Για ποιο χρονικό διάστημα αποθηκεύουμε τα προσωπικά σας δεδομένα? </b></p><p>Η Εταιρεία μας διατηρεί αρχικά τα δεδομένα σας για όσο χρονικό διάστημα 
  είναι απαραίτητα ανάλογα με τον εκάστοτε σκοπό επεξεργασίας. Εάν τα δεδομένα δεν απαιτούνται πλέον για την εκπλήρωση των σκοπών επεξεργασίας 
  διαγράφονται, εκτός εάν η αποθήκευσή τους εξακολουθεί να είναι απαραίτητη για λόγους που είναι αναγκαίοι λόγω εκτέλεσης της σύμβασης ή για λόγους
   που προβλέπει η νομοθεσία (π.χ φορολογικοί). </p><p>Θα θέλαμε ειδικότερα να σας ενημερώσουμε ότι σε περίπτωση που δεν είστε ενεργός χρήστης και
    δεν χρησιμοποιείτε για χρονικό διάστημα 5 ετών ενεργά την εφαρμογή μας και κατ ́επέκταση τα προγράμματα πιστότητας που προσφέρουμε μέσω χρήσης 
    της συλλογής και εξαργύρωσης πόντων, η Εταιρεία θα σας αποστείλει ενημερωτικό μήνυμα προκειμένου να σας ρωτήσει αν επιθυμείτε να διατηρήσει 
    περαιτέρω τα δεδομένα σας ή να τα διαγράψει. Αν η Εταιρεία δε λάβει κάποια απάντηση εντός 60 ημερών τα δεδομένα σας θα διαγραφούν.
     </p><p></p><p><b>Μεταβιβάζονται τα προσωπικά σας δεδομένα σε τρίτες χώρες?</b> </p><p>Τα προσωπικά σας δεδομένα δε μεταβιβάζονται σε τρίτες χώρες.
     Αν προκύψει τυχόν τέτοια μεταβίβαση η Εταιρεία δεσμεύεται να λάβει όλα τα κατάλληλα μέτρα που προβλέπει ο ΓΚΠΔ για την προστασία των προσωπικών 
     σας δεδομένων. </p><p><b>Αποδέκτες των δεδομένων </b></p><p>Στο πλαίσιο των προγραμμάτων πιστότητας, η Εταιρεία συνεργάζεται με άλλες εταιρείες οι οποίες
      αποκτούν πρόσβαση σε όσα δεδομένα σας είναι απαραίτητα για την εξυπηρέτηση του εκάστοτε κάθε φορά προγράμματος. Στο πλαίσιο αυτό τηρούνται 
      όλες οι συμβατικές δεσμεύσεις για την προστασία των προσωπικών σας δεδομένων. Επίσης δύναται σε ορισμένες περιπτώσεις τα δεδομένα σας να 
      διαβιβαστούν σε αρμόδιες διοικητικές ή δικαστικές αρχές στο πλαίσιο εκπλήρωσης καθήκοντος που εκτελείται προς το δημόσιο συμφέρον βάσει 
      των εκάστοτε προβλέψεων του νόμου. </p><p><b>Πως προστατεύονται τα προσωπικά σας δεδομένα?</b> </p><p>Η Εταιρεία έχει λάβει όλα τα απαραίτητα τεχνικά 
      και οργανωτικά μέτρα για την προστασία των προσωπικών σας δεδομένων από τυχαία ή αθέμιτη καταστροφή, τυχαία απώλεια, αλλοίωση, απαγορευμένη 
      διάδοση ή πρόσβαση και κάθε άλλη μορφή αθέμιτης επεξεργασίας. Οι προσωπικά αναγνωρίσιμες πληροφορίες που συλλέγουμε αποθηκεύονται με ασφάλεια 
      στη βάση δεδομένων μας και χρησιμοποιούμε τυπικές, ευρέως εμπορικά διαδεδομένες πρακτικές ασφαλείας, όπως η κρυπτογράφηση και τα firewalls και SSL 
      (Secure Socket Layers) για την προστασία των πληροφοριών σας – όπως αναφορά στον αριθμό της Κάρτας Πληρωμών σας τον οποίο πιθανόν χρησιμοποιούμε
      (δεν διατηρούμε τον αριθμό της Κάρτας Πληρωμών, παρά ένα μη αναστρέψιμα υπολογιζόμενο κωδικό αριθμό που αντιστοιχεί με τον αριθμό της, ο οποίος
       φυλάσσεται σε εξειδικευμένο και πιστοποιημένο για την ασφάλεια πληρωμών σύστημα). Η Εταιρεία φροντίζει για την εξουσιοδοτημένη πρόσβαση στα
        προσωπικά σας δεδομένα και για την τήρηση εμπιστευτικότητας εκ μέρους των αρμοδίων ατόμων. Η Εταιρεία μέσω των συμβατικών δεσμεύσεων με τους 
        συνεργάτες της τηρεί όλες τις απαραίτητες νομικές επιταγές για την ασφαλή επεξεργασία των προσωπικών σας πληροφοριών. </p><p>Ωστόσο, όσο 
        αποτελεσματική και αν είναι η τεχνολογία κρυπτογράφησης, κανένα σύστημα ασφαλείας δεν είναι απροσπέλαστο. Δεν μπορούμε να εγγυηθούμε την
         ασφάλεια της βάσης δεδομένων μας, ούτε μπορούμε να εγγυηθούμε ότι οι πληροφορίες που παρέχετε δεν θα υποκλαπούν καθώς μεταδίδονται σε 
         εμάς μέσω του διαδικτύου, και κάθε πληροφορία που μεταδίδεται στην εταιρεία γίνεται με δική σας ευθύνη.</p><p></p><p><b>Ποια είναι τα δικαιώματα σας,
          σχετικά με την επεξεργασία των προσωπικών σας δεδομένων; </b></p><p>Τα δικαιώματα που μπορείτε να ασκήσετε περιλαμβάνουν: </p><p><b>Δικαίωμα πρόσβασης:</b>
           Έχετε το δικαίωμα να λάβετε ενημέρωση σχετικά με τα προσωπικά δεδομένα σας που επεξεργαζόμαστε και να σας χορηγήσουμε αντίγραφα αυτών. 
           </p><p><b>Δικαίωμα διόρθωσης:</b> Έχετε το δικαίωμα να ζητήσετε τη διόρθωση των δεδομένων σας (π.χ. διόρθωση της διεύθυνσης). </p><p><b>Δικαίωμα διαγραφής:</b>
            Έχετε το δικαίωμα να ζητήσετε τη διαγραφή των προσωπικών σας δεδομένων σε περίπτωση που αυτά δεν είναι πλέον απαραίτητα σε σχέση με τον
             σκοπό επεξεργασίας. </p><p><b>Δικαίωμα περιορισμού της επεξεργασίας:</b> Έχετε το δικαίωμα να ζητήσετε τον περιορισμό της επεξεργασίας για
              συγκεκριμένο σκοπό. </p><p><b>Δικαίωμα εναντίωσης</b> στην επεξεργασία των προσωπικών σας δεδομένων στις περιπτώσεις που δεν επιθυμείτε την 
              επεξεργασία των προσωπικών σας δεδομένων. </p><p><b>Δικαίωμα φορητότητας</b> των δεδομένων σας όπως κι εφόσον μπορεί ενίοτε να εφαρμοσθεί. 
              </p><p></p><p><b>Πως μπορείτε να ασκήσετε τα δικαιώματά σας?</b> </p><p>Για την άσκηση των δικαιωμάτων σας, μπορείτε να:</p><p>στείλετε e-mail στην 
              ηλεκτρονική διεύθυνση: info@roadcube.com. </p><p>Προκειμένου να διευκολυνθείτε στην υποβολή ενός αιτήματος και 
προκειμένου να έχουμε όλες τις απαραίτητες πληροφορίες για να μπορέσουμε να απαντήσουμε εγκαίρως, σας συμβουλεύουμε να χρησιμοποιήσετε αυτή τη φόρμα 
(λινκ με τη φορμα). Σε κάθε περίπτωση ίσως χρειαστούμε κάποιες επιπρόσθετες πληροφορίες για την ικανοποίηση του αιτήματός σας οπότε και θα σας 
ενημερώσουμε σχετικά. </p><p>Όταν λάβουμε το αίτημά σας θα σας απαντήσουμε εντός 30 ημερών ή σε περίπτωση πολυπλοκότητας του αιτήματος η προθεσμία 
μπορεί να παραταθεί άλλες 60 ημέρες και σε αυτήν την περίπτωση θα ενημερωθείτε αναλόγως. </p><p>Ακόμα, διατηρείτε το δικαίωμα υποβολής καταγγελίας 
στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (Λ. Κηφισίας 1-3 Τ.Κ.115 23, Αθήνα +30 210 6475600, email επικοινωνίας contact@dpa.gr). 
</p><p><b>Αλλαγές σε αυτή τη δήλωση απορρήτου </b></p><p>Δύναται να ενημερώνουμε αυτή τη δήλωση προστασίας απορρήτου ώστε να εκφράζει τις ενίοτε αλλαγές μας 
στον τρόπο που διαχειριζόμαστε πληροφορίες. Αν κάνουμε οποιεσδήποτε ουσιώδεις αλλαγές θα σας ενημερώσουμε μέσω email (που θα αποστέλλεται στη διεύθυνση 
ηλεκτρονικού ταχυδρομείου που καθορίζεται στο λογαριασμό σας) ή μέσω ειδοποίησης σε αυτή την ιστοσελίδα, πριν η αλλαγή αυτή γίνει ενεργή. Σας 
ενθαρρύνουμε να επανεξετάζετε περιοδικά αυτή τη σελίδα για τις τελευταίες πληροφορίες σχετικά με τις πρακτικές απορρήτου μας. Η παρούσα δήλωση 
απορρήτου αποτελεί μέρος της συμφωνίας των όρων και προϋποθέσεων και ερμηνεύεται σύμφωνα με τους σχετικούς όρους της τελευταίας. Ημερομηνία τελευταίας 
αναθεώρησης: 18/3/2021 </p><p><b>Δήλωση απορρήτου για τους παρόχους</b> </p><p>Η προστασία του απορρήτου είναι σημαντική για την Roadcube. Γι’ αυτό και έχουμε 
αναπτύξει μια Δήλωση Απορρήτου η οποία καλύπτει το πώς συλλέγουμε, χρησιμοποιούμε, αποκαλύπτουμε, μεταβιβάζουμε και αποθηκεύουμε τις πληροφορίες σας.
 Αφιερώστε λίγο χρόνο για να εξοικειωθείτε με τις πρακτικές απορρήτου μας και ενημερώστε μας αν έχετε οποιεσδήποτε απορίες. </p><p>Αρχικά θα θέλαμε να
  ενημερώσουμε τους παρόχους ότι ζήτημα προστασίας των προσωπικών δεδομένων τίθεται βάσει του ΓΚΠΔ στην περίπτωση των φυσικών προσώπων. </p><p>Αυτά 
  καθ’ εαυτά τα νομικά πρόσωπα δε θεωρούνται υποκείμενα προσωπικών δεδομένων εκτός αν μπορεί να γίνει ταυτοποίηση του προσώπου και τότε θα εξεταστεί 
  από τον υπεύθυνο επεξεργασίας η προστασία των προσωπικών πληροφοριών. </p><p>Επομένως η Roadcube ανάλογα με τα εκάστοτε στοιχεία στο πλαίσιο της εκάστοτε
   διαδικασίας θα κρίνει εάν πρόκειται αποκλειστικά και μόνο για στοιχεία νομικού προσώπου ή αν πρόκειται για περίπτωση όπου μέλη του νομικού προσώπου
    μπορούν άμεσα ή έμμεσα να ταυτοποιηθούν. Η περιγραφόμενη επομένως δήλωση απορρήτου αφορά αυτήν ακριβώς την περίπτωση, όπου πρόκειται για πληροφορίες
     που δύναται να οδηγήσουν στην ταυτότητα φυσικού προσώπου. </p><p></p><p>Παρακαλούμε δείτε περισσότερες πληροφορίες στη γενική δήλωση απορρήτου που αφορά
      την περίπτωση επεξεργασίας προσωπικών δεδομένων των φυσικών προσώπων. </p>
      </v-container>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";

  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {        
        
      };
    },
  };
</script>
